import { Input, message } from 'antd'
import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import axios from 'axios'
const ResetPassword = () => {

  const { token } = useParams()
  const [isTokenValid, setIsTokenValid] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isResetPassword, setIsResetPassword] = useState(false)

  const checkToken = () => {
    console.log(token);
    axios.post('https://admin.jm-interim.com:3000/auth/check-password-token', { token })
      .then((res) => setIsTokenValid(true))
      .catch((e) => setIsTokenValid(false))
  }

  const resetPassword = () => {
    if (password.length < 8) {
      message.error('Le mot de passe doit faire au moins 8 caractères !')
      return
    }

    if (password !== confirmPassword) {
      message.error('Mot de passe differents ! ')
      return
    }
    axios.post('https://admin.jm-interim.com:3000/auth/reset-password', { token, password })
      .then((res) => {
        setIsResetPassword(true)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    checkToken()
  }, [token])

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {isTokenValid && !isResetPassword && (
        <div className="h-screen flex flex-col space-y-4 items-center justify-center">
          <p className="text-2xl mb-10">Reinitialisez votre mot de passe</p>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded-md border  w-2/5     border-gray-300 mb-2"
            type="password"
            placeholder="Mot de passe"
          />
          <Input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="rounded-md border  w-2/5    border-gray-300 mb-2"
            type="password"
            placeholder="Confirmer mot de passe"
          />
          <button
            onClick={resetPassword}
            className="bg-green-500   w-2/5  p-2 text-white font-semibold text-lg rounded-md"
          >
            Reinitialiser mot de passe
          </button>
        </div>
      )}

      {isTokenValid && isResetPassword && (
        <div className="flex w-1/2 flex-col h-1/2 items-center justify-center border space-y-6 rounded-md border-green-300 p-4">
          <p className="text-green-400 font-semibold text-xl">
            Mot de passe reinitialisé ! Vous pouvez vous connecter
          </p>
        </div>
      )}

      {!isTokenValid && (
        <div>
          <p>Le lien est invalide</p>
        </div>
      )}
    </div>
  )
}

export default ResetPassword
