import { useEffect, useState } from "react"
import axios from "axios";
import { Input, message } from "antd";
import { useParams } from "react-router-dom";

const ActivateAccount = () => {


  const { token } = useParams()
  const [isTokenValid, setIsTokenValid] = useState(true)
  const [password, setPassword] = useState('')
  const [isActivated,setIsActivated] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('')
  const [user, setUser] = useState(null)

  const checkToken = () => {
    axios.post('https://admin.jm-interim.com:3000/auth/check-token', { token })
      .then((res) => {
        console.log(res.data)
        setUser(res.data)
      })
      .catch((e) => console.log(e))
  }

  const activatePassword = () => {
    if (password.length < 8) {
      message.error('Le mot de passe doit faire au moins 8 caractères !')
      return
    }

    if (password !== confirmPassword) {
      message.error('Mot de passe differents ! ')
      return
    }
    axios.post('https://admin.jm-interim.com:3000/auth/set-password-for-mobile', {
      token,
      password,
      user: user.user,
    })
      .then((res) => {
        setIsActivated(true);
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    checkToken()
  }, [token])

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {isTokenValid && !isActivated && (
        <div className="h-screen flex flex-col space-y-4 items-center justify-center">
          <p className="text-2xl mb-10">Choisissez votre mot de passe</p>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded-md border  w-full    border-gray-300 mb-2"
            type="password"
            placeholder="Mot de passe"
          />
          <Input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="rounded-md border  w-full   border-gray-300 mb-2"
            type="password"
            placeholder="Confirmer mot de passe"
          />
          <button
            onClick={activatePassword}
            className="bg-green-500   w-full  p-2 text-white font-semibold text-lg rounded-md"
          >
            Activer mon compte
          </button>
        </div>
      )}

      {isTokenValid && isActivated && (
        <div className="flex w-3/5 flex-col h-1/2 items-center justify-center border space-y-6 rounded-md border-green-300 p-4">
          <p className="text-green-400 font-semibold text-xl">
            Votre compte est activé ! Vous pouvez vous connecter sur l'application Mon interim.
          </p>
        </div>
      )}

      {!isTokenValid && (
        <div>
          <p>Le lien est invalide</p>
        </div>
      )}
    </div>
  )

}

export default ActivateAccount;