import './App.css';
import { Route, Routes } from 'react-router-dom';
import ResetPassword from './pages/ResetPassword';
import ActivateAccount from './pages/ActivateAccount';
import Success from './pages/Success';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<div>Home page</div>} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/activate/:token" element={<ActivateAccount />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </div>
  )
}

export default App;
