import imagesuccess from '../assets/img/imagesuccess.png';

const Success = () => {

    return (
        <div className="h-screen w-full flex items-center justify-center">
            <div className='lg:w-1/3 bg-[#f3fbff] pb-10 rounded-sm'>
            <img src={imagesuccess} className='w-36' />
            <div className='flex flex-col items-center p-10 justify-center'>

            <p className="text-lg text-center uppercase font-semibold text-blue-900">Merci pour votre réponse !</p>
            <p className="text-sm text-center mt-10  font-semibold  text-blue-900">Votre candidature a été prise en compte.</p>
            <p className="text-sm text-center font-semibold mt-10 text-blue-900">Si votre profil est selectionné, noux reviendrons vers vous dans les meilleurs délais.</p>
            </div>

            </div>
        </div>
    )
}

export default Success